<template>
  <div>
    <BoxTitle :title="'1.4 Queremos saber más de usted'">
      <template v-slot:content>¡Queremos conocerla un poco mejor!</template>
    </BoxTitle>
    <BoxField :title="'¿Con cuantas entidades tiene deuda activa sin contar con nosotros?'">
      <template v-slot:content>
        <v-text-field v-model.number="request.num_entities_debt" label="Sus otras deudas" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'Cuéntenos un poquito sobre usted, ¿cómo se definiría?'">
      <template v-slot:content>
        <v-text-field v-model="request.definition_yourself" label="Definición" />
      </template>
    </BoxField>
    <BoxField :title="'Evitar penalizaciones es lo que me motiva a pagar las cuotas de mi crédito a tiempo'" :onlyFirstCredit="true">
      <template v-slot:content>
        <v-select
          v-model.number="request.avoid_penalties"
          label="Seleccione una respuesta"
          :items="form_selects_values.know_more_answers"
        />
      </template>
    </BoxField>
    <BoxField
      :title="'Mi sentido de responsabilidad personal es lo que me motiva a pagar las cuotas de mi crédito a tiempo'"
      :onlyFirstCredit="true"
    >
      <template v-slot:content>
        <v-select
          v-model.number="request.personal_responsability"
          label="Seleccione una respuesta"
          :items="form_selects_values.know_more_answers"
        />
      </template>
    </BoxField>
    <BoxField
      :title="'Crear nuevas oportunidades para mi negocio es lo que me motiva a pagar las cuotas de mi crédito a tiempo'"
      :onlyFirstCredit="true"
    >
      <template v-slot:content>
        <v-select
          v-model.number="request.new_opportunities"
          label="Seleccione una respuesta"
          :items="form_selects_values.know_more_answers"
        />
      </template>
    </BoxField>
    <BoxField
      :title="'La relación que tengo con los promotores de las financieras es lo que me motiva a pagar las cuotas de mi crédito a tiempo'"
      :onlyFirstCredit="true"
    >
      <template v-slot:content>
        <v-select
          v-model.number="request.promoters_relationship"
          label="Seleccione una respuesta"
          :items="form_selects_values.know_more_answers"
        />
      </template>
    </BoxField>
    <BoxField
      :title="'¿Cuál es la razón principal y más importante por la que usted paga las cuotas de su crédito a tiempo?'"
      :onlyFirstCredit="true"
    >
      <template v-slot:content>
        <v-text-field v-model="request.reason_commitment_payment" label="Razón" />
      </template>
    </BoxField>
    <BoxField :title="'Si alguna vez se ha retrasado en pagar su cuota ¿cuál fue la razón?'" :onlyFirstCredit="true">
      <template v-slot:content>
        <v-text-field v-model="request.reason_late_payment" label="Razón" />
      </template>
    </BoxField>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BoxTitle from "@/components/common/BoxTitle.vue";
import BoxField from "@/components/common/BoxField.vue";

export default {
  components: { BoxField, BoxTitle },
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState("request", ["form_selects_values"])
  }
};
</script>
