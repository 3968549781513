<template>
  <div>
    <BoxTitle :title="'1.2 Datos del fiador'">
      <template v-slot:content
        >Es un requisito que una persona de su confianza haga de garante del crédito. Esa persona firmará junto a usted el contrato de
        préstamo. En esta sección le pedimos que rellene los datos de la misma.</template
      >
    </BoxTitle>
    <BoxField :title="'Nombre'">
      <template v-slot:content>
        <v-text-field v-model="request.guarantor_name" label="Nombre de su fiador" />
      </template>
    </BoxField>
    <BoxField :title="'Apellidos'">
      <template v-slot:content>
        <v-text-field v-model="request.guarantor_last_name" label="Apellidos de su fiador" />
      </template>
    </BoxField>
    <BoxField :title="'Nº de teléfono'">
      <template v-slot:content>
        <v-text-field v-model="request.guarantor_phone" label="Nº de teléfono de su fiador" />
      </template>
    </BoxField>
    <BoxField :title="'Ingresos'">
      <template v-slot:content>
        <v-text-field v-model.number="request.guarantor_income" label="¿Cuántos ingresos tiene su fiador?" type="number" />
      </template>
    </BoxField>
  </div>
</template>

<script>
import BoxTitle from "@/components/common/BoxTitle.vue";
import BoxField from "@/components/common/BoxField.vue";

export default {
  components: { BoxField, BoxTitle },
  props: {
    request: {
      type: Object,
      required: true
    }
  }
};
</script>
