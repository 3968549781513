<template>
  <div>
    <BoxTitle :title="'2.2 Análisis de negocio'">
      <template v-slot:content>
        En esta sección queremos entender los ingresos y gastos que usted prevé tener en el negocio para el cual usted solicita este
        crédito.
      </template>
    </BoxTitle>
    <BoxField :title="'En su negocio hoy en día, ¿cuales son sus ganancias mensuales?'">
      <template v-slot:content>
        <v-text-field
          v-model.number="request.business_income"
          label="Ganancias mensuales"
          type="number"
          :rules="formRules.numberRulesAllowZero"
        />
      </template>
    </BoxField>
    <BoxField
      :title="'¿Cuánto cree que va a poder ingresar de este negocio al mes, teniendo en cuenta la inversión realizada con el crédito que está solicitando?'"
    >
      <template v-slot:content>
        <v-text-field
          v-model.number="request.service.income"
          label="Ingresos mensuales"
          type="number"
          :rules="formRules.numberRulesAllowZero"
        />
      </template>
    </BoxField>
    <BoxField :title="'¿Cuánto cree que va a tener que invertir en el negocio para cubrir sus gastos?'">
      <template v-slot:content>
        <v-text-field v-model.number="request.service.expenses" label="Gastos mensuales" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'Si renta el espacio de su negocio, ¿cuánto paga al mes por la renta?'">
      <template v-slot:content>
        <v-text-field v-model.number="request.rental_costs" label="Coste" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'¿Cuánto paga al mes por consumibles?'">
      <template v-slot:content>
        <v-text-field v-model.number="request.consumable_costs" label="Costes" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'¿Cuánto paga al mes por maquinaria o animales? Por ejemplo: motos, coches, maquina de cocina, vacas, cerdos'">
      <template v-slot:content>
        <v-text-field v-model.number="request.machinery_costs" label="Costes" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'¿Cada mes, cuánto dinero saca usted de su negocio para pagar por gastos del hogar?'">
      <template v-slot:content>
        <v-text-field v-model.number="request.other_monthly_expenses" label="Gastos en empleados" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'Si tiene empleados en el negocio, ¿cuánto gasta al mes pagándoles?'">
      <template v-slot:content>
        <v-text-field v-model.number="request.employee_expenses" label="Gastos en empleados" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'¿Lleva un control de las cuentas de su negocio?'">
      <template v-slot:content>
        <v-select v-model="request.account_control" label="Seleccione una opción" :items="form_selects_values.accounts_control" />
      </template>
    </BoxField>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import _ from "lodash";
import BoxTitle from "@/components/common/BoxTitle.vue";
import BoxField from "@/components/common/BoxField.vue";

export default {
  components: { BoxField, BoxTitle },
  mixins: [FormRulesMixin],
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState("request", ["form_selects_values"]),
    _() {
      return _;
    }
  }
};
</script>
