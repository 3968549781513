<template>
  <div>
    <BoxTitle :title="'1.1 Datos de la solicitante'">
      <template v-slot:content>Como solicitante del crédito, le pedimos que en esta sección rellene sus datos personales.</template>
    </BoxTitle>
    <BoxField v-if="borrower_data.first_name" :title="'Nombre'" :readonly="true">
      <template v-slot:content>
        <v-text-field :value="borrower_data.first_name" readonly />
      </template>
    </BoxField>
    <BoxField v-if="borrower_data.last_name" :title="'Apellidos'" :readonly="true">
      <template v-slot:content>
        <v-text-field :value="borrower_data.last_name" readonly />
      </template>
    </BoxField>
    <BoxField :title="'Estado civil'" :onlyFirstCredit="true">
      <template v-slot:content>
        <v-select v-model="request.civil_status" label="Su estado civil" :items="form_selects_values.civils_status" />
      </template>
    </BoxField>
    <BoxField :title="'¿Qué importe quiere recibir para su negocio?'">
      <template v-slot:content>
        <v-text-field v-model.number="request.amount_requested" label="Cantidad" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'Cuéntenos sobre los productos que quieres vender, o los servicios que quiere ofrecer. ¡Cuánto más detalle mejor!'">
      <template v-slot:content>
        <v-text-field v-model="request.business_description" label="Descripción de su negocio" />
      </template>
    </BoxField>
    <BoxField :title="'¿Cuántos hijos tiene que dependen de sus ganancias?'" :onlyFirstCredit="true">
      <template v-slot:content>
        <v-text-field v-model.number="request.number_children" label="Nº de niños" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'¿Cuántos familiares o amigos viven con usted y dependen de sus ganancias?'" :onlyFirstCredit="true">
      <template v-slot:content>
        <v-text-field v-model.number="request.number_adults" label="Nº de adultos" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'Celular'">
      <template v-slot:content>
        <v-checkbox v-model="request.has_phone" :label="request.has_phone ? '¿Tiene celular propio?: Sí' : '¿Tiene celular propio?: No'" />
      </template>
    </BoxField>
    <BoxField :title="'¿A qué tipo de celular tiene acceso en su día a día?'">
      <template v-slot:content>
        <v-select v-model="request.phone_type" label="Tipo de celular" :items="form_selects_values.phone_types" />
      </template>
    </BoxField>
    <BoxField :title="'¿Tiene usted vivienda propia o paga renta?'" :onlyFirstCredit="true">
      <template v-slot:content>
        <v-select v-model="request.living_place" label="Su vivienda" :items="form_selects_values.living_places" />
      </template>
    </BoxField>
    <BoxField :title="'¿Usted o alguien con quién vive posee vehículo propio?'" :onlyFirstCredit="true">
      <template v-slot:content>
        <v-checkbox
          v-model="request.own_vehicle"
          :label="request.own_vehicle ? '¿Tiene vehículo propio?: Sí' : '¿Tiene vehículo propio?: No'"
        />
      </template>
    </BoxField>
    <BoxField :title="'¿Cuánto gasta en temas de salud?'">
      <template v-slot:content>
        <v-text-field v-model.number="request.health_expenses" label="Sus gastos en salud" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'¿Cuánto dinero gasta, aproximadamente al mes, para hacer mejoras en su casa?'">
      <template v-slot:content>
        <v-text-field v-model.number="request.house_improvement_expenses" label="Sus gastos en mejoras en casa" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'¿Cuánto gasta en otras cosas? Por ejemplo: ropa, diversión, libros'">
      <template v-slot:content>
        <v-text-field v-model.number="request.other_expenses" label="Sus gastos en otras cosas" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'¿Aproximadamente, cuánto dinero consigue ahorrar mensualmente?'">
      <template v-slot:content>
        <v-text-field v-model.number="request.money_save" label="Sus ahorras mensuales" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'Ahorramos mejor cuando es con propósito, ¿usted para qué ahorra?'">
      <template v-slot:content>
        <v-select v-model="request.savings_target" label="Sus ahorros son para" :items="form_selects_values.savings_targets" />
      </template>
    </BoxField>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import BoxTitle from "@/components/common/BoxTitle.vue";
import BoxField from "@/components/common/BoxField.vue";

export default {
  components: { BoxField, BoxTitle },
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      form_selects_values: (state) => state.request.form_selects_values,
      borrower_data: (state) => state.borrower.borrower_data
    }),
    _() {
      return _;
    }
  }
};
</script>
