<template>
  <div>
    <!-- Navgation -->
    <v-row class="mb-8">
      <v-col v-if="selectedScreen > 1" cols="12" md="4" class="d-flex align-center" style="cursor: pointer" @click="previousScreen()">
        <v-icon id="arrow-back-screen">arrow_left</v-icon>
        <p class="mb-0">Pantalla <b>ANTERIOR</b></p>
      </v-col>
      <v-col cols="12" md="8" class="d-flex align-center justify-center justify-md-end">
        <p class="mb-0 mr-2">
          <b>Pantalla {{ selectedScreen }}</b> de {{ totalScreens }}
        </p>
        <v-icon
          v-for="screen in totalScreens"
          :key="screen"
          :color="screenHighlight(screen)"
          class="navigation-dot"
          :class="screen == selectedScreen ? 'navigation-dot-selected' : ''"
          @click="selectedScreen = screen"
        >
          fiber_manual_record
        </v-icon>
      </v-col>
    </v-row>

    <!-- Forms -->
    <v-form ref="formEditRequest" v-model="formEditRequestValid" lazy-validation @submit.prevent="">
      <BorrowerData v-if="selectedScreen === 1" :request="request" />
      <GuarantorData v-if="selectedScreen === 2" :request="request" />
      <BusinessData v-if="selectedScreen === 3" :request="request" />
      <BorrowerData2 v-if="selectedScreen === 4" :request="request" />
      <HomeAnalysis v-if="selectedScreen === 5" :request="request" />
      <BusinessAnalysis v-if="selectedScreen === 6" :request="request" />
      <FinalScreen v-if="selectedScreen === 7" />
    </v-form>

    <!-- Buttons -->
    <div class="d-none d-md-flex justify-space-around">
      <v-btn class="normal-button" rounded @click="previousScreen()">
        <span class="font-weight-bold">ATRÁS</span>
      </v-btn>
      <v-btn v-if="selectedScreen === totalScreens" class="primary normal-button" rounded @click="edit()">
        <span class="font-weight-bold">FINALIZAR</span>
      </v-btn>
      <v-btn v-if="selectedScreen < totalScreens" class="normal-button" rounded @click="nextScreen()">
        <span class="font-weight-bold">SIGUIENTE</span>
      </v-btn>
    </div>

    <v-row class="d-md-none text-center">
      <v-col cols="12">
        <v-btn rounded class="normal-button" @click="previousScreen()">
          <span class="font-weight-bold">ATRÁS</span>
        </v-btn>
      </v-col>
      <v-col v-if="selectedScreen === totalScreens" cols="12">
        <v-btn class="primary normal-button" rounded @click="edit()">
          <span class="font-weight-bold">FINALIZAR</span>
        </v-btn>
      </v-col>
      <v-col v-if="selectedScreen < totalScreens" cols="12">
        <v-btn rounded class="normal-button" @click="nextScreen()">
          <span class="font-weight-bold">SIGUIENTE</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import BorrowerData from "@/components/credit-forms/BorrowerData.vue";
import GuarantorData from "@/components/credit-forms/GuarantorData.vue";
import BusinessData from "@/components/credit-forms/BusinessData.vue";
import BorrowerData2 from "@/components/credit-forms/BorrowerData2.vue";
import HomeAnalysis from "@/components/credit-forms/HomeAnalysis.vue";
import BusinessAnalysis from "@/components/credit-forms/BusinessAnalysis.vue";
import FinalScreen from "@/components/credit-forms/FinalScreen.vue";

export default {
  components: { BorrowerData, GuarantorData, BusinessData, BorrowerData2, HomeAnalysis, BusinessAnalysis, FinalScreen },
  name: "NewCredit",
  data() {
    return {
      panel: [0],
      formEditRequestValid: false,
      request: {
        id: this.$store.state.request.id,
        business_address: {
          coordinates: {
            lat: 0,
            lon: 0
          },
          name: ""
        },
        product_type: "SERVICES_OR_VARIOUS_PRODUCTS",
        service: {
          expenses: 0,
          income: 0
        }
      },
      totalScreens: 7,
      selectedScreen: 1
    };
  },
  computed: {
    ...mapState("request", ["form_selects_values"]),
    _() {
      return _;
    }
  },
  async created() {
    if (!this.$store.state.request.id) this.$router.push("/");
    else await this.$store.dispatch("common/getSectors");
  },
  methods: {
    // Edit request
    async edit() {
      if (this.$refs.formEditRequest.validate()) {
        let requestFormattedData = { ...this.request };

        // Delete business_address if business_address.name is empty
        if (requestFormattedData.business_address.name === "") delete requestFormattedData.business_address;

        requestFormattedData = this.cleanObject(requestFormattedData);
        this.$set(requestFormattedData.service, "type", ""); // Set type like empty string (is necessary for validation)

        await this.$store.dispatch("request/editRequest", requestFormattedData);
        this.$router.push("/requests");
      }
    },

    // Return if value is empty
    isEmptyValues(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },

    cleanObject(o) {
      for (var k in o) {
        if (typeof o[k] !== "object") {
          if (this.isEmptyValues(o[k])) delete o[k];
        } else {
          // The property is an object
          this.cleanObject(o[k]); // <-- Make a recursive call on the nested object
          if (Object.keys(o[k]).length === 0) {
            delete o[k]; // The object had no properties, so delete that property
          }
        }
      }
      return o;
    },

    screenHighlight(screen) {
      return screen === this.selectedScreen ? "#00f9ad" : "#CFCFCF";
    },

    previousScreen() {
      if (this.selectedScreen > 1) this.selectedScreen = this.selectedScreen - 1;
      this.$vuetify.goTo(0);
    },

    nextScreen() {
      if (this.selectedScreen < this.totalScreens) this.selectedScreen = this.selectedScreen + 1;
      this.$vuetify.goTo(0);
    }
  }
};
</script>

<style scoped>
.v-expansion-panel .v-expansion-panel-header,
.v-expansion-panel .v-expansion-panel-content {
  background-color: #d4d4d4;
}
.v-expansion-panel--active .v-expansion-panel-header,
.v-expansion-panel--active .v-expansion-panel-content {
  background-color: #f5f5f5;
}
.navigation-dot {
  font-size: 30px;
}
.navigation-dot-selected {
  border: 1px solid black;
  border-radius: 50%;
}
#arrow-back-screen {
  font-size: 50px;
  color: black;
}
</style>
