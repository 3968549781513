<template>
  <div>
    <BoxTitle :title="'2.1 Análisis del hogar'">
      <template v-slot:content>
        Ingresos y gastos de su familia, NO relacionados con el negocio para el que está solicitando el crédito.
      </template>
    </BoxTitle>
    <BoxField :title="'Si tiene ganancias de algún otro negocio suyo o sueldo fijo de un empleo, indique la cantidad que ingresa al mes'">
      <template v-slot:content>
        <v-text-field v-model.number="request.other_business_income" label="Sueldo de otro empleo" type="number" />
      </template>
    </BoxField>
    <BoxField
      :title="'Si algún otro familiar con el que vive tiene ganancias de otro negocio o sueldo fijo de un empleo, indique qué cantidad ingresa al mes este familiar.'"
    >
      <template v-slot:content>
        <v-text-field v-model.number="request.income_another_family" label="Ingresos" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'¿Cuánto gasta al mes en su renta, luz y agua?'">
      <template v-slot:content>
        <v-text-field v-model.number="request.house_expenses" label="Total de gastos" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'¿Cuánto gasta al mes en alimentar a su familia?'">
      <template v-slot:content>
        <v-text-field v-model.number="request.food_expenses" label="Total de gastos" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'¿Cuánto gasta al mes en la educación de sus hijos o familiares?'">
      <template v-slot:content>
        <v-text-field v-model.number="request.education_expenses" label="Total de gastos" type="number" />
      </template>
    </BoxField>
  </div>
</template>

<script>
import BoxTitle from "@/components/common/BoxTitle.vue";
import BoxField from "@/components/common/BoxField.vue";

export default {
  components: { BoxField, BoxTitle },
  props: {
    request: {
      type: Object,
      required: true
    }
  }
};
</script>
