<template>
  <div>
    <BoxTitle :title="'1.3 Información del negocio'">
      <template v-slot:content>
        El crédito debe emplearse para ser invertido en un emprendimiento! Cuéntenos sobre el negocio que quiere usted implementar o
        mejorar.
      </template>
    </BoxTitle>
    <BoxField :title="'Dirección'">
      <template v-slot:content>
        <v-text-field v-model="request.business_address.name" label="Dirección de su negocio" />
      </template>
    </BoxField>
    <BoxField :title="'¿Quiere mejorar un negocio ya existente o comenzar uno nuevo?'">
      <template v-slot:content>
        <v-select v-model="request.business_objetive" label="Negocio existente o nuevo" :items="form_selects_values.business_objetives" />
      </template>
    </BoxField>
    <BoxField
      :title="'¿Cuántas personas, incluyéndote a ti misma, trabajaban o ayudan en el negocio?'"
      :description="'(Si hay personas que apoyan al negocio de manera parcial, puedes poner fracciones. Ejemplo: tu hijo te ayuda unas 4 horas al día, la respuesta sería: 1.5) *'"
    >
      <template v-slot:content>
        <v-text-field v-model.number="request.business_employees" label="Personas que trabajarán en su negocio" type="number" />
      </template>
    </BoxField>
    <BoxField :title="'Sector'">
      <template v-slot:content>
        <v-autocomplete
          v-model="request.business_sector"
          :items="$store.state.common.sectors"
          item-text="value.name"
          item-value="key"
          label="¿Cuál es el sector de su negocio?"
        />
      </template>
    </BoxField>
    <BoxField :title="'Tipo'">
      <template v-slot:content>
        <v-text-field v-model="request.business_type" label="¿Qué tipo de negocio es? (restaurante, tienda de ropa...)" />
      </template>
    </BoxField>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BoxTitle from "@/components/common/BoxTitle.vue";
import BoxField from "@/components/common/BoxField.vue";

export default {
  components: { BoxField, BoxTitle },
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState("request", ["form_selects_values"])
  }
};
</script>
